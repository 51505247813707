import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'igpw-sspOrder',
    default: {
        resourceGroup: null,
        subNet: null,
        virtualNetwork: null,
        appGatewayType: 'no',
        appProfile: null,
        region: null,
        appProfileDetails: null,
        appOwner: null,
        appOwnerDetails: null,
        hCode: null,
        hCodeDetails: null,
        entityDetails: null,
        environment: null,
        envShortName: null,
        regionDisplayname: null,
        appTier: null,
        appTierCode: null,
        program: null,
        applicationName: null,
        sector: null,
        appGWIPAddress: null,
        sectorDetails: null,
        dataClassification: null,
        subscription: null,
        applicationGwTier: 'Application Gateway Standard v2',
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the Application Gateway New Instance Provisioning onboarding in the shared Application Gateway.',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const uploadedFilesState = atom({
    key: 'igpw-uploadedFilesState',
    default: [],
});

export const environmentDetails = atom({
    key: 'igpw-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        // 'Application gateway Name': '---',
    },
});

export const isValidApplicationName = atom({
    key: 'igpw-validApplicationName',
    default: false,
});
export const isAppUrlValid = atom({
    key: 'igpw-isAppUrlValid',
    default: false,
});
export const isValidBackendHealthProbe = atom({
    key: 'igpw-validBackENdHealthProbe',
    default: false,
});
export const isValidFqdns = atom({
    key: 'igpw-isValidFqdns',
    default: false,
});
// export const isValidISANumber = atom({
//     key: 'igpw-isValidISANumber',
//     default: false,
// });
// export const isValidScanNumber = atom({
//     key: 'igpw-isValidScanNumber',
//     default: false,
// });
export const toggleSspModels = atom({
    key: 'igpw-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isADGroupModalOpen: false,
        isNonPrivilegedADGroupOpen: false,
        isPrivilegedADGroupOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'igpw-isMandatoryTagsFullfilled',
    default: false,
});

export const isEnvDetailsLoading = atom({
    key: 'igpw-isEnvDetailsLoading',
    default: false,
});
export const costEstimateState = atom({
    key: 'igpw-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'igpw-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const orderId = atom({
    key: 'igpw-orderId',
    default: '',
});

export const isValidappSupportMailId = atom({
    key: 'igpw-isValidappSupportMailId',
    default: false,
});

export const isValidAppGWPrivateIPAddress = atom({
    key: 'igpw-isValidAppGWPrivateIPAddress',
    default: false,
});

// export const isValidOwners = atom({
//     key: 'igpw-isValidOwners',
//     default: {
//         isvalidAppOwner: true,
//         isvalidCertificateOwner1: true,
//         isvalidCertificateOwner2: true,
//     },
// });

export const isAppShortNameValid = atom({
    key: 'igpw-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'igpw-isAppShortNameExist',
    default: false,
});
export const isAppNameValid = atom({
    key: 'igpw-isAppNameValid',
    default: true,
});
export const isNonPrivilegedADGroupValid = atom({
    key: 'igpw-isNonPrivilegedADGroupValid',
    default: false,
});

export const isPrivilegedADGroupValid = atom({
    key: 'igpw-isPrivilegedADGroupValid',
    default: false,
});
